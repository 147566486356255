












































































































































import FormatDate from '@/helpers/FormatDate'
import PreviewText from '@/helpers/PreviewText'
import {
  EFileFormat,
  EUserStatus,
  TopicAttachFile,
  TopicComment
} from '@/models'
import { Prop, Component, Vue } from 'vue-property-decorator'
import CheckFileFormat from '@/helpers/CheckFileExtension'

@Component
export default class ModalCommentRoot extends Vue {
  @Prop() private commentDetail!: TopicComment
  private noAvatar = require('@/assets/images/avatar-default.png')
  private eFileFormat = EFileFormat

  showCommenterProfile(commenterId: number) {
    this.$emit('showCommenterProfile', commenterId)
  }
  fullOverview(src: string) {
    this.$emit('fullOverview', src)
  }
  downloadFile(file: TopicAttachFile) {
    this.$emit('downloadFile', file)
  }

  /**
   * Handle long text
   */
  handleLongText(comment: TopicComment) {
    let text = this.hanldeUserStatus(
      comment.commenter_name,
      comment.user_active_status
    )
    const preview = PreviewText.covertToPreviewText(text)
    return {
      name: text,
      text: preview.text,
      isLong: preview.isLong
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  /**
   * Format date
   */
  formatDate(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  getFileFormat(extension: string) {
    return CheckFileFormat.getFileFormat(extension)
  }

  getFormatIconClass(extension: string) {
    return CheckFileFormat.getIconClassNameWithFileExtention(extension)
  }

  handleFileNameWithSize(attachFile: TopicAttachFile) {
    const previewText = PreviewText.covertToPreviewText(attachFile.name, 30)
      .text
    if (!attachFile.size) return previewText
    const bytes = Number(attachFile.size)
    let sizeText = '0 KB'
    if (bytes) {
      if (bytes < 1) {
        sizeText = '1 KB'
      } else {
        const k = 1024
        const sizes = ['KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        sizeText =
          parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
      }
    }
    return previewText + ` (${sizeText})`
  }
}
