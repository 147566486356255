















































import PreviewText from '@/helpers/PreviewText'
import { Prop, Component, Vue } from 'vue-property-decorator'
import ModalShowProfile from '@/components/Modal/ModalShowProfile.vue'
import { EUserStatus } from '@/models'

@Component({ components: { ModalShowProfile } })
export default class ModalLikers extends Vue {
  @Prop({ default: 'display-liker' }) id!: string
  @Prop() listLiker!: ListLiker[]
  private selectedLikerId: number = 1
  private noAvatar = require('@/assets/images/avatar-default.png')
  private eUserStatus = EUserStatus

  /**
   * Show member profile modal
   */
  async showMemberProfile(liker: ListLiker) {
    if (liker.user_active_status) return
    this.selectedLikerId = liker.id
    // const sameGroupTag = await this.getUserProfileById()
    // if (sameGroupTag) {
    this.$bvModal.show('modal-liker-profile')
    // }
  }

  /**
   * Check user is same group tag
   */
  // async getUserProfileById() {
  //   return UserService.getUserProfileById(this.selectedLikerId)
  //     .then(res => {
  //       return res.status === 200 && res.data.is_same_group_tag
  //     })
  //     .catch(() => {
  //       return false
  //     })
  // }

  /**
   * Handle long text
   */
  handleLongText(liker: ListLiker) {
    let text = this.hanldeUserStatus(liker.liker_name, liker.user_active_status)
    const preview = PreviewText.covertToPreviewText(text, 20)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }
}

export interface ListLiker {
  id: number
  avatar: string
  liker_name: string
  user_active_status: EUserStatus
}
