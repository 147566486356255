






























































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import FormatDate from '@/helpers/FormatDate'
import PreviewText from '@/helpers/PreviewText'
import CheckDevice from '@/helpers/CheckDevice'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import GroupService from '@/services/GroupService'
import GroupTopicService from '@/services/GroupTopicService'
import CommonTopicService from '@/services/CommonTopicService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import ModalLikers from '@/components/Modal/ModalLikers.vue'
import ModalCommentRoot from '@/components/Modal/ModalCommentRoot.vue'
import ModalShowProfile from '@/components/Modal/ModalShowProfile.vue'
import TopicCommentArea from '@/views/Group/TopPage/NewFeed/TopicCommentArea.vue'
import LoadingTopicComment from '@/components/Loading/LoadingTopicComment.vue'
import {
  EFileFormat,
  EUserStatus,
  Topic,
  TopicAttachFile,
  TopicComment,
  TopicCommentParams,
  TopicDetails,
  TopicLiker
} from '@/models'
import {commentRef} from '@/firebase'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'
import {EhumbNailsDisplay} from '@/models/Setting/Enum'
import {getCommonTopicCount} from '@/helpers/GetNotificationCount'
import {getModule} from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'
import linkifyHtml from "linkify-html";

const CommonTopicModule = getModule(CommonTopic, store)

@Component({
  components: {
    TopicCommentArea,
    ModalShowProfile,
    ModalLikers,
    ModalCommentRoot,
    LoadingTopicComment
  }
})
export default class TopicDetailComment extends Vue {
  //start screen
  @Prop() private ableComment!: boolean
  @Prop() private isInBin!: boolean
  @Prop() private createrId!: number
  private topicDetail!: TopicDetails
  private roleEditTopic: boolean = false
  private allowAnonymous!: boolean
  private enableLike!: boolean
  private hideLiker!: boolean
  private likeText: string = this.$t('groups.nav_button.like') as string
  private isCommonTopic = !this.$route.params.groupId
  private maxLikerDisplay: number = 10
  private maxFileSize: any = {
    mb: this.isCommonTopic
      ? 25
      : Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes: this.isCommonTopic
      ? 25 * 1048576
      : Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
  }
  private isTyping: boolean = false
  private isTypingReply: any[] = []
  private displayThumbnail: boolean =
    this.$store.state.setting.setting.setting_display.display_image_video ===
    EhumbNailsDisplay.DISPLAY_THUMBNAILS

  //data
  private currentComments: TopicComment[] = []
  private commentById: TopicComment = new TopicComment()
  private eFileFormat = EFileFormat
  private eUserStatus = EUserStatus
  private commentSortNew: boolean = true

  //API helper
  private page: number = 1
  private lastPage: number = 1
  private searchPage: number = 1
  private pageUp: number = 1
  private pageDown: number = 1
  private isPageUp: boolean = false
  private isPageDown: boolean = false
  private loadMoreUp: boolean = false
  private disablePageUp: boolean = false
  private disablePageDown: boolean = false
  private limitComment: number = this.$store.state.setting.setting
    .setting_display.number_items_list_comment
  private currentUser = this.$store.state.userInfo.user.info

  //view model
  private justSubmitNewComment: boolean = false
  private replyCommentId: number = 0
  private replyCommentIdCollapse: number = 0
  private commenterId: number = -1
  private blinkCommentId: number = -1
  private fullOverviewSrc: string = ''
  private noAvatar = require('@/assets/images/avatar-default.png')
  private isLoading: boolean = true
  private unreadCommentIdStart: number = 0
  private unreadCommentIdEnd: number = 0
  private unsubscribe: any = () => {}

  created() {
    this.init()
  }

  destroyed() {
    this.unsubscribe()
  }

    handleMarkRead() {
    if (this.isCommonTopic) {
      CommonTopicService.markAsCommonTopicRead(
        Number(this.$route.params.topicId)
      )
      getCommonTopicCount()
    } else {
      GroupTopicService.markAsRead(
        this.$route.params.groupId,
        Number(this.$route.params.topicId)
      )
    }
    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(true)
  }

  handleCommentRealtime() {
    let trackingRealtime = true
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = commentRef
      .where('topicId', '==', this.$route.params.topicId)
      .where('createdAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          if (!querySnapshot.docChanges().empty) {
            querySnapshot.docChanges().forEach((change: any) => {
              if (change.type === 'added') {
                const newCommentId = change.doc.data().commentIds[0]
                if (newCommentId) {
                  this.getCommentById(newCommentId)
                }
              }
            })
          }
        }
        this.handleMarkRead()
      })
  }

  @Watch('$route.query.commentSearchId')
  watchCommentSearchId() {
    if (!this.$route.query.commentSearchId) return
    this.init()
  }

  @Watch('$route.params.topicId')
  async init() {
    this.reset()
    this.handleMaxFileSize()
    await this.getCurrentTopic()
    await this.getUnreadCommentIdEnd()
    await this.getAllComments(this.page)
    this.handleRoleEditTopic()
    this.handleModalCommentParent()
    this.handleCommentRealtime()
  }

  reset() {
    this.page = 1
    this.lastPage = 1
    this.searchPage = 1
    this.pageUp = 1
    this.pageDown = 1
    this.isPageUp = false
    this.isPageDown = false
    this.loadMoreUp = false
    this.disablePageUp = false
    this.disablePageDown = false
    this.justSubmitNewComment = false
    this.replyCommentId = 0
    this.replyCommentIdCollapse = 0
    this.commenterId = -1
    this.blinkCommentId = -1
    this.isLoading = true
    this.unreadCommentIdStart = 0
    this.unreadCommentIdEnd = 0
    this.unsubscribe()
  }

  handleCommentStatus(status: boolean) {
    this.isTyping = status
    this.emitConfirmLeave()
  }

  handleCommenReplyStatus(status: boolean, conmmentId: number) {
    const finder = this.isTypingReply.find(item => item.id === conmmentId)
    if (finder) {
      const index = this.isTypingReply.indexOf(finder)
      this.isTypingReply[index].status = status
    } else {
      this.isTypingReply.push({ id: conmmentId, status: status })
    }
    this.emitConfirmLeave()
  }

  @Watch('$store.state.userInfo.user.group_role')
  handleRoleEditTopic() {
    if (this.isCommonTopic) {
      this.roleEditTopic = false
      // this.currentTopic.creater_id === this.$store.state.userInfo.user.info.id
    } else {
      this.roleEditTopic = this.$store.state.userInfo.user.group_role.role_edit_topic
    }
  }

  emitConfirmLeave() {
    this.$emit(
      'confirmLeave',
      this.isTyping ||
        (this.isTypingReply.length
          ? this.isTypingReply.every(item => item.status)
          : false)
    )
  }

  @Watch('commentSortNew')
  sortCommnet() {
    this.page = 1
    this.pageUp = 1
    this.pageDown = 1
    this.searchPage = 1
    this.getAllComments(this.page)
  }

  @Watch('$route.query.commentId')
  async handleModalCommentParent() {
    if (this.$route.query.commentId && this.topicDetail.topic_title) {
      await this.getCommentById(Number(this.$route.query.commentId), true)
      this.$bvModal.show('parent-comment-modal')
      this.$router.replace({ query: {} }).catch(() => {})
    }
  }

  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: this.isCommonTopic
        ? 25
        : Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes: this.isCommonTopic
        ? 25 * 1048576
        : Number(this.$store.state.userInfo.user.current_group_max_file_size) *
          1048576
    }
  }

  /**
   * get all comments
   */
  async getAllComments(
    page: number,
    loadMore?: boolean,
    isLoadMoreUp?: boolean
  ) {

    this.page = page

    if (this.isCommonTopic) {
      await this.getAllCommonTopicComments(page, loadMore, isLoadMoreUp)
    } else {
      await this.getAllGroupTopicComments(page, loadMore, isLoadMoreUp)
    }

    //if page is min (=1) disable page up forever
    this.disablePageUp = this.page === 1

    //if page is max (= lastPage) disable page down forever
    this.disablePageDown = this.page === this.lastPage
  }

  async getCurrentTopic() {
    if (this.isCommonTopic) {
      return CommonTopicService.getCommonTopicById(
        this.$route.params.topicId
      ).then(res => {
        if (res.status === 200) {
          this.setCurrentTopic(new Topic(res.data))
        }
      })
    } else {
      return GroupTopicService.getTopicById(
        this.$route.params.groupId,
        this.$route.params.topicId
      ).then(res => {
        if (res.status === 200) {
          this.setCurrentTopic(new Topic(res.data))
        }
      })
    }
  }

  setCurrentTopic(topic: Topic) {
    this.topicDetail = topic.topic_detail
    this.allowAnonymous = this.topicDetail.allow_anonymous_comment
    this.enableLike = this.topicDetail.enable_like
    this.hideLiker = this.topicDetail.hide_liker
    this.topicDetail.like_character_change
      ? (this.likeText = this.topicDetail.like_character_change)
      : ''
    this.unreadCommentIdStart = this.topicDetail.last_seen_comment_id
      ? this.topicDetail.last_seen_comment_id
      : 1
  }

  /**
   * get all comments
   */
  async getUnreadCommentIdEnd() {
    if (this.isCommonTopic) {
      return CommonTopicService.getAllCommentByCommonTopicId({
        topic_id: this.$route.params.topicId,
        order_by: 'created_at',
        sorted_by: 'desc',
        is_commenter_hidden: 1,
        page: 1,
        limit_comment: 1
      }).then(res => {
        if (res.status === 200 && res.data.data.length) {
          if (!res.data.data[0].is_deleted)
          this.unreadCommentIdEnd = res.data.data[0].id
        }
      })
    } else {
      return GroupTopicService.getAllCommentByTopicId({
        group_id: this.$route.params.groupId,
        topic_id: this.$route.params.topicId,
        order_by: 'created_at',
        sorted_by: 'desc',
        is_commenter_hidden: 1,
        page: 1,
        limit_comment: 1
      }).then(res => {
        if (res.status === 200 && res.data.data.length) {
          if (!res.data.data[0].is_deleted)
          this.unreadCommentIdEnd = res.data.data[0].id
        }
      })
    }
  }

  /**
   * Call API get all comments of group topic
   */
  async getAllGroupTopicComments(
    page: number,
    loadMore?: boolean,
    isLoadMoreUp?: boolean
  ) {
    this.isLoading = true
    return GroupTopicService.getAllCommentByTopicId({
      group_id: this.$route.params.groupId,
      topic_id: this.$route.params.topicId,
      order_by: 'created_at',
      sorted_by: this.commentSortNew ? 'desc' : 'asc',
      is_commenter_hidden: this.allowAnonymous ? 1 : 0,
      page: page,
      limit_comment: this.limitComment,
      comment_id: this.$route.query.commentSearchId
        ? (this.$route.query.commentSearchId as string)
        : ''
    })
      .then(res => {
        if (res.status === 200) {
          this.handleAfterGetAllComment(res, loadMore, isLoadMoreUp)
        }
      })
      .catch(err => (this.$parent as any).handleTopicNotFound(err))
      .finally(() => (this.isLoading = false))
  }

  /**
   * Call API get all comments of common topic
   */
  async getAllCommonTopicComments(
    page: number,
    loadMore?: boolean,
    isLoadMoreUp?: boolean
  ) {
    this.isLoading = true
    return CommonTopicService.getAllCommentByCommonTopicId({
      topic_id: this.$route.params.topicId,
      order_by: 'created_at',
      sorted_by: this.commentSortNew ? 'desc' : 'asc',
      is_commenter_hidden: this.allowAnonymous ? 1 : 0,
      page: page,
      limit_comment: this.limitComment,
      comment_id: this.$route.query.commentSearchId
        ? (this.$route.query.commentSearchId as string)
        : ''
    })
      .then(res => {
        this.handleAfterGetAllComment(res, loadMore, isLoadMoreUp)
      })
      .catch(err => (this.$parent as any).handleTopicNotFound(err))
      .finally(() => (this.isLoading = false))
  }

  /**
   * Hanlde after call API get all comment
   */
  handleAfterGetAllComment(
    res: any,
    loadMore?: boolean,
    isLoadMoreUp?: boolean
  ) {
    if (res.status === 200) {
      //handle page
      this.lastPage = res.data.last_page
      if (this.$route.query.commentSearchId) {
        this.page = res.data.page
        this.pageUp = res.data.page
        this.pageDown = res.data.page
        this.searchPage = res.data.page
        this.disablePageDown = this.page === this.lastPage
        this.disablePageUp = this.page === 1
        this.loadMoreUp = res.data.page !== 1

        //scroll
        this.justSubmitNewComment = true
        this.handleCommentBlink(Number(this.$route.query.commentSearchId))
        setTimeout(() => {
          this.$router.replace({ query: {} }).catch(() => {})
        }, 1000)
      }

      //handle data
      const data = res.data.data.map((item: any) => new TopicComment(item))

      if (loadMore) {
        //handle data
        let loadMoreData = data.filter((item: any) => {
          // filter comment has been deleted
          let existIndex = this.currentComments.findIndex(comment =>
              comment.id == item.id
          )

          if (existIndex == -1)
          return new TopicComment(item)
        })

        this.currentComments = isLoadMoreUp
          ? [...loadMoreData, ...this.currentComments]
          : [...this.currentComments, ...loadMoreData]
      } else {
        this.currentComments = data
      }

      this.$emit('setTotalComment', res.data.total)
    }
  }

  /**
   * Call API to get comment just add
   */
  async getCommentById(commentId: number, noHandleAfter?: boolean) {
    if (this.isCommonTopic) {
      return CommonTopicService.getCommonTopicCommentById(commentId)
        .then(res => {
          if (res.status === 200) {
            let data = res.data
            if (this.allowAnonymous) {
              data.commenter_name = ''
              data.commenter_avatar = ''
            }
            this.commentById = new TopicComment(data)
            !noHandleAfter ? this.handleAfterAddComment(commentId) : ''
          }
        })
        .catch(err => (this.$parent as any).handleTopicNotFound(err))
    } else {
      return GroupTopicService.getCommentById(
        this.$route.params.groupId,
        commentId
      )
        .then(res => {
          if (res.status === 200) {
            let data = res.data
            if (this.allowAnonymous) {
              data.commenter_name = ''
              data.commenter_avatar = ''
            }
            this.commentById = new TopicComment(data)
            !noHandleAfter ? this.handleAfterAddComment(commentId) : ''
          }
        })
        .catch(err => (this.$parent as any).handleTopicNotFound(err))
    }
  }

  handleAfterAddComment(commentId: number) {
    if (this.commentSortNew) {
      this.currentComments = [this.commentById, ...this.currentComments]
      this.handleCommentBlink(commentId)
    } else {
      const lastestComment = this.currentComments[
        this.currentComments.length - 1
      ]
      if (this.commentById.no === lastestComment.no + 1) {
        this.currentComments = [...this.currentComments, this.commentById]
        this.handleCommentBlink(commentId)
      } else {
        this.handleCommentBlink(lastestComment.id, true)
      }
    }
  }

  handleCommentBlink(commentId: number, noBlink?: boolean) {
    const vm = this

    setTimeout(() => {
      if (this.justSubmitNewComment) {
        ;(vm.$refs[`comment-${commentId}`] as any)[0]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        })
      }
      noBlink ? '' : (this.blinkCommentId = commentId)
    }, 1)
    setTimeout(() => {
      vm.blinkCommentId = -1
      this.justSubmitNewComment = false
    }, 800)
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    //if last loadmore is page up -> set current page to last page down
    if (this.isPageUp) this.page = this.pageDown

    //set loadmore to page down
    this.isPageDown = true

    //re-count page
    ++this.page

    //if current page is search page (search page is have data loaded at screen start), continues re-count
    if (this.page === this.searchPage) ++this.page

    //set last page down = this current page
    this.pageDown = this.page

    //if page is max (= lastPage) disable page down forever
    this.disablePageDown = this.page === this.lastPage

    //call API to get data
    this.getAllComments(this.page, true)
  }

  hanldeLoadMoreUp() {
    //if last loadmore is page down -> set current page to last page up
    if (this.isPageDown) this.page = this.pageUp

    //set loadmore to page up
    this.isPageUp = true

    //re-count page
    --this.page

    //if current page is search page (search page is have data loaded at screen start), continues re-count
    if (this.page === this.searchPage) --this.page

    //set last page up = this current page
    this.pageUp = this.page

    //if page is min (=1) disable page up forever
    this.disablePageUp = this.page === 1

    //call API to get data
    this.getAllComments(this.page, true, true)
  }

  /**
   * Sort comment by newest or older
   */
  handleSortComment() {
    this.commentSortNew = !this.commentSortNew
  }

  /**
   * Call API for like or dislike comment
   */
  handleLikeComment(index: number) {
    let currentCmt = this.currentComments[index]
    this.handleAfterLikeComment(currentCmt)
    if (this.isCommonTopic) {
      CommonTopicService.markAsLikeCommonTopicComment(
        this.$route.params.topicId,
        currentCmt.id
      )
        .then(res => {
          if (res.status !== 200) {
            this.handleAfterLikeComment(currentCmt)
          }
        })
        .catch(err => {
          this.handleAfterLikeComment(currentCmt)
          ;(this.$parent as any).handleTopicNotFound(err)
        })
    } else {
      GroupTopicService.markAsLikeComment(
        this.$route.params.groupId,
        this.$route.params.topicId,
        currentCmt.id
      )
        .then(res => {
          if (res.status !== 200) {
            this.handleAfterLikeComment(currentCmt)
          }
        })
        .catch(err => {
          this.handleAfterLikeComment(currentCmt)
          ;(this.$parent as any).handleTopicNotFound(err)
        })
    }
  }

  /**
   * Handle after call API like or dislike topic
   */
  handleAfterLikeComment(currentCmt: TopicComment) {
    const currentUser = this.$store.state.userInfo.user
    currentCmt.is_like = !currentCmt.is_like
    if (currentCmt.is_like) {
      const newLiker = new TopicLiker({
        id: currentUser.info.id,
        liker_name: currentUser.info.name,
        avatar: currentUser.profile.avatar_small
      })
      currentCmt.liker = [newLiker, ...currentCmt.liker]
    } else {
      const newLikers = currentCmt.liker.filter(
        item => item.id !== currentUser.info.id
      )
      currentCmt.liker = newLikers
    }
  }

  /**
   * Toggle comment reply collapse
   */
  async toggleReplyCollapse(id: number) {
    if (this.replyCommentId === id) {
      //close collapse
      this.replyCommentIdCollapse = 0
      setTimeout(() => (this.replyCommentId = 0), 100)
    } else {
      //open collapse
      this.replyCommentId = id
      setTimeout(() => {
        this.replyCommentIdCollapse = id

        //scroll and focus
        let el = document.getElementById(`commentBox-${id}`)
        window.setTimeout(() => {
          ;(el as HTMLElement).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })
          ;(el as HTMLElement).focus()
        }, 0)
      }, 100)
    }
  }

  /**
   * Call API to submit comment reply
   */
  submitNewComment(newComment: TopicCommentParams, cmtId?: number) {
    let formData = new FormData()
    formData.set('topic_id', String(this.$route.params.topicId))
    formData.set('content_comment', newComment.content_comment)
    formData.set('content_text', newComment.content_comment_text)
    cmtId ? formData.set('comment_id', String(cmtId)) : ''
    //for tiny editor
    newComment.tiny_file_ids.forEach(id => {
      formData.append('file_ids[]', String(id))
    })

    //attach file
    if (newComment.attach_file) {
      newComment.attach_file.forEach((file: File) => {
        formData.append('attach_file[]', file)
      })
    }
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonTopicService.addNewCommonTopicComment(formData)
        .then(res => {
          if (res.status === 201) {
            this.justSubmitNewComment = true

            //close collape
            this.replyCommentId = 0
            cmtId ? '' : (this.$refs as any).commentBoxParent.clearComment()
          }
        })
        .catch(err => {
          this.$emit('addCommentError', err)
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupTopicService.addNewComment(this.$route.params.groupId, formData)
        .then(res => {
          if (res.status === 201) {
            this.justSubmitNewComment = true

            //close collape
            this.replyCommentId = 0

            cmtId ? '' : (this.$refs as any).commentBoxParent.clearComment()
          }
        })
        .catch(err => {
          this.$emit('addCommentError', err)
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Open comfirm delete comment
   */
  openConfirm(commentId: number) {
    this.$emit('confirmDelCmt', commentId)
  }

  /**
   * Open modal liker
   */
  openLikerModal(index: number) {
    this.$bvModal.show(`comment-liker-list-${index}`)
  }

  /**
   * Format date
   */
  formatDate(date: string) {
    return FormatDate.formatFullDatetime(date)
  }

  /**
   * Show commenter profile modal
   */
  async showCommenterProfile(comment: TopicComment) {
    if (comment.user_active_status !== EUserStatus.NORMAL) return
    const userId = comment.commenter_id
    if (!this.allowAnonymous) {
      this.commenterId = userId
      // const sameGroupTag = await this.getUserProfileById()
      // if (sameGroupTag) {
      this.$bvModal.show('commenter-profile')
      // }
    }
  }

  /**
   * Check user is same group tag
   */
  // async getUserProfileById() {
  //   return UserService.getUserProfileById(this.commenterId)
  //     .then(res => {
  //       return res.status === 200 && res.data.is_same_group_tag
  //     })
  //     .catch(() => {
  //       return false
  //     })
  // }

  /**
   * Handle long text
   */
  handleLongText(text: string, max?: number) {
    return PreviewText.covertToPreviewText(text ? text : '', max)
  }

  /**
   * Call API to get download files stream
   */
  downloadFile(file: TopicAttachFile) {
    //handle dowload IOS not safari
    if (
      CheckDevice.iOSBrowerName().toLowerCase() !== CheckDevice.safari &&
      CheckDevice.isMobile() &&
      CheckDevice.getMobileOS() === CheckDevice.ios
    ) {
      window.open(file.path)
      return
    }

    //download other
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.downloadCommonFiles([file.id])
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res, file)
          }
        })
        .catch(err => (this.$parent as any).handleTopicNotFound(err))
        .finally(() => this.$blockui.hide())
    } else {
      GroupService.downloadFiles(this.$route.params.groupId, [file.id])
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res, file)
          }
        })
        .catch(err => (this.$parent as any).handleTopicNotFound(err))
        .finally(() => this.$blockui.hide())
    }
  }

  hanldeDownloadFile(res: any, file: TopicAttachFile) {
    const bytes = new Uint8Array(res.data)
    const blob = new Blob([bytes], { type: '*' })
    let a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.setAttribute('target', '_blank')
    a.setAttribute('download', file.name)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  handleFileNameWithSize(attachFile: TopicAttachFile) {
    if (!attachFile.size) return this.handleLongText(attachFile.name, 40).text
    const bytes = Number(attachFile.size)
    let sizeText = '0 KB'
    if (bytes) {
      if (bytes < 1) {
        sizeText = '1 KB'
      } else {
        const k = 1024
        const sizes = ['KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        sizeText =
          parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
      }
    }
    return this.handleLongText(attachFile.name, 40).text + `(${sizeText})`
  }

  getFileFormat(extension: string) {
    return CheckFileFormat.getFileFormat(extension)
  }

  getFormatIconClass(extension: string) {
    return CheckFileFormat.getIconClassNameWithFileExtention(extension)
  }

  handleCommenterName(comment: TopicComment) {
    let text = this.hanldeUserStatus(
      comment.commenter_name,
      comment.user_active_status
    )
    const preview = PreviewText.covertToPreviewText(text)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  /**
   * Open full view for image file
   */
  fullOverview(src: string) {
    this.fullOverviewSrc = src
    this.$bvModal.show('comment-full-image-overview')
  }

  jumpToParentComment(commentId?: number) {
    if (!commentId) return
    this.$router
      .replace({ query: { commentId: String(commentId) } })
      .catch(() => this.$bvModal.show('parent-comment-modal'))
  }

  handleLinkifyContent(content: string) {
    return linkifyHtml(content || '', { target: "_blank" })
  }

  filterDeletedComment(commentId: number) {
    // filter comment has been deleted
    let deletedComment = this.currentComments.findIndex(comment =>
      comment.id == commentId
    )
    this.currentComments[deletedComment].is_deleted = true

    // filter child comment of deleted comment
    this.currentComments.forEach((item, index) => {
      if (item.comment_id == commentId) {
        this.currentComments[index].comment_parent_delete = true
      }
    })
  }
}
