var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"parent-comment-modal","header-class":"pb-0","body-class":"px-5","centered":"","scrollable":"","hide-footer":""}},[_c('div',{staticClass:"pb-10 px-2"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pe-2 pt-4 fs-10 fs-xl-12"},[_vm._v(" "+_vm._s(("No." + (_vm.commentDetail.no)))+" ")]),_c('div',{staticClass:"d-flex flex-column flex-1",attrs:{"id":("comment-id-" + (_vm.commentDetail.id))}},[_c('div',{staticClass:"commenter-info d-flex align-items-center py-2"},[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",on:{"click":function($event){return _vm.showCommenterProfile(_vm.commentDetail.commenter_id)}}},[_c('img',{staticClass:"rounded-pill h--7 w--7 h-xl--9 w-xl--9 img-cover flex-fixed",attrs:{"src":_vm.commentDetail.commenter_avatar
                  ? _vm.commentDetail.commenter_avatar
                  : _vm.noAvatar}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
                customClass: _vm.handleLongText(_vm.commentDetail).isLong
                  ? 'd-block'
                  : 'd-none'
              }),expression:"{\n                customClass: handleLongText(commentDetail).isLong\n                  ? 'd-block'\n                  : 'd-none'\n              }",modifiers:{"hover":true,"right":true}}],staticClass:"mx-2 my-1 text-line-clamp-1",class:{
                'text-secondary-tint-less': _vm.commentDetail.user_active_status
              },attrs:{"title":_vm.commentDetail.commenter_name}},[_vm._v(" "+_vm._s(_vm.commentDetail.commenter_name ? _vm.handleLongText(_vm.commentDetail).text : _vm.$t('groups.new_feed.anonymous'))+" ")])]),_c('div',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.formatDate(_vm.commentDetail.comment_date)))])]),(_vm.commentDetail.comment_id)?_c('div',{staticClass:"ps-7"},[(!_vm.commentDetail.comment_parent_delete)?_c('span',{staticClass:"fwb"},[_vm._v(" "+_vm._s(_vm.commentDetail.comment_parent_no ? ("＞No." + (_vm.commentDetail.comment_parent_no)) : '')+" ")]):_c('span',{staticClass:"text-secondary-tint-less pb-1"},[_vm._v(" "+_vm._s(("＞No." + (_vm.commentDetail.comment_parent_no)))+" ")])]):_vm._e(),_c('div',{staticClass:"normal-a-tag comment-content word-break ps-7 text-pre",domProps:{"innerHTML":_vm._s(_vm.commentDetail.content)}}),(_vm.commentDetail.attach_file)?_c('div',_vm._l((_vm.commentDetail.attach_file),function(detail,attachFileDetailIndex){return _c('div',{key:attachFileDetailIndex,staticClass:"loop-attach-file-comment"},[(_vm.getFileFormat(detail.extension) === _vm.eFileFormat.IMAGE)?_c('div',{staticClass:"d-flex align-items-center ps-7"},[_c('img',{staticClass:"mt-1 rounded-1 img-cover flex-fixed cursor-pointer me-2 h--9 w--9",attrs:{"src":detail.small_path},on:{"click":function($event){return _vm.fullOverview(detail.path)}}}),_c('a',{staticClass:"text-primary text-break cursor-pointer",on:{"click":function($event){return _vm.downloadFile(detail)}}},[_vm._v(" "+_vm._s(_vm.handleFileNameWithSize(detail))+" ")])]):_c('div',{staticClass:"d-flex align-items-center text-primary pt-1 ps-7"},[_c('div',{staticClass:"h--9 w--9 d-flex flex-center"},[_c('i',{class:("fs-30 fs-xl-25 " + (_vm.getFormatIconClass(detail.extension)))})]),_c('a',{staticClass:"px-2 text-primary text-break cursor-pointer",on:{"click":function($event){return _vm.downloadFile(detail)}}},[_vm._v(" "+_vm._s(_vm.handleFileNameWithSize(detail))+" ")])])])}),0):_vm._e(),(
            _vm.commentDetail.total_attach_file &&
              _vm.commentDetail.deleted_attach_file
          )?_c('div',{staticClass:"ps-7 text-secondary-tint-less pt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('groups.new_feed.some_attach_file_delete', { count: _vm.commentDetail.deleted_attach_file }))+" ")])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }